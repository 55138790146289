<script setup lang="ts">
import { ApplicationInsights } from '@microsoft/applicationinsights-web'

const appConfig = useAppConfig()
const appInsights = new ApplicationInsights({
  config: {
    connectionString: appConfig.APP_INSIGHTS_CONNECTION_STRING as string,
  },
})
appInsights.loadAppInsights()
appInsights.trackPageView()
</script>

<template>
  <VitePwaManifest />
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
</template>
