export default defineNuxtRouteMiddleware(async (to) => {
  const { $acquireTokenSilent } = useNuxtApp()
  const accessToken = (await $acquireTokenSilent())?.accessToken
  const { attemptedPath } = storeToRefs(useAuthStore())

  // const accessToken = sessionStorage.getItem('authToken')

  if (to.path !== '/' && !accessToken) {
    attemptedPath.value = to.fullPath
    return navigateTo('/')
  }
  else if (to.path === '/' && accessToken) {
    return navigateTo('/projects')
  }
})
