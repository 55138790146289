export const useAuthStore = defineStore(
  'auth',
  () => {
    const currentUser = ref<User | null>(null)
    const authToken = ref<string | null>(null)
    const authError = ref<null | string>('')
    const attemptedPath = ref<string | null>(null)
    const isAdmin = ref<boolean>(false)
    const userContext = ref<UserContext | null>(null)
    const isProtectasUser = ref<boolean>(false)

    watch(currentUser, (newUser) => {
      isProtectasUser.value = isProtectasEmail(newUser?.userPrincipalName ?? '')
    })

    function isProtectasEmail(email: string): boolean {
      if (!email)
        return false

      const protectasEmailRegex = /^.+@protectas\.com$/
      return protectasEmailRegex.test(email)
    }

    return {
      currentUser,
      authToken,
      authError,
      attemptedPath,
      isAdmin,
      userContext,
      isProtectasUser,
    }
  },
  { persist: true },
)
