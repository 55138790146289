import * as Sentry from '@sentry/nuxt'

export default defineNuxtPlugin(async () => {
  function initSentry() {
    const currentClient = Sentry.getClient()
    if (currentClient) {
      return
    }
    const t = useNuxtApp().$i18n.t
    Sentry.init({
      environment: useAppConfig().SENTRY_ENVIRONMENT as string,
      dsn: useAppConfig().SENTRY_DSN as string,
      integrations: [
        Sentry.replayIntegration(),
        Sentry.feedbackIntegration({
          colorScheme: 'system',
          triggerLabel: t('trigger_label'),
          formTitle: t('form_title'),
          nameLabel: t('name_label'),
          namePlaceholder: t('name_placeholder'),
          emailLabel: t('email_label'),
          emailPlaceholder: t('email_placeholder'),
          messageLabel: t('message_label'),
          messagePlaceholder: t('message_placeholder'),
          isRequiredLabel: t('required_field'),
          addScreenshotButtonLabel: t('add_screenshot_button'),
          removeScreenshotButtonLabel: t('remove_screenshot_button'),
          submitButtonLabel: t('submit_button'),
          cancelButtonLabel: t('cancel_button'),
          successMessageText: t('success_message'),
        }),
      ],
      tracesSampleRate: 1.0, //  Capture 100% of the transactions
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: [
        'localhost',
        /^https:\/\/[^/]*advisor\.securitas\.com/, // Matches advisor.securitas.com and all its subdomains
      ],
      // Session Replay
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
      ignoreErrors: ['is not a function'],
    })
  }
  initSentry()
})
