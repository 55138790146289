import { defineNuxtPlugin } from '#app/nuxt'
import { LazySvgoAddCircle, LazySvgoAddImages, LazySvgoAdvisor, LazySvgoAdvisorBlack, LazySvgoAdvisorGray, LazySvgoAdvisorText, LazySvgoArrowDown, LazySvgoArrowDownFilled, LazySvgoArrowLeft, LazySvgoArrowRight, LazySvgoArrowUp, LazySvgoArrowUpFilled, LazySvgoBook, LazySvgoCamera, LazySvgoChange, LazySvgoCheck, LazySvgoClearCircle, LazySvgoClearWithBackground, LazySvgoClickHere, LazySvgoClose, LazySvgoCloseWithBackground, LazySvgoCollapse, LazySvgoCompanies, LazySvgoCross, LazySvgoDashboard, LazySvgoDashboardOutline, LazySvgoDownload, LazySvgoEdit, LazySvgoEllipse, LazySvgoEmail, LazySvgoError, LazySvgoExpand, LazySvgoGlobalWorld, LazySvgoGridView, LazySvgoHamburger, LazySvgoInfo, LazySvgoListView, LazySvgoLocation, LazySvgoLock, LazySvgoMitigateCheck, LazySvgoMoreFilled, LazySvgoMyProfile, LazySvgoPdf, LazySvgoPhone, LazySvgoPlus, LazySvgoProjects, LazySvgoProjectsAddNew, LazySvgoProtectasTextLogo, LazySvgoProtectasTextLogoBlack, LazySvgoRiskAssessment, LazySvgoSearch, LazySvgoSecuritasLogo, LazySvgoSecuritasTextLogo, LazySvgoSecuritasTextLogoBlack, LazySvgoShare, LazySvgoStatusCompleted, LazySvgoStatusDraft, LazySvgoStatusInProgress, LazySvgoSuccess, LazySvgoThreeDots, LazySvgoTooltipArrowUp, LazySvgoTrash, LazySvgoUpload, LazySvgoUsers } from '#components'
const lazyGlobalComponents = [
  ["SvgoAddCircle", LazySvgoAddCircle],
["SvgoAddImages", LazySvgoAddImages],
["SvgoAdvisor", LazySvgoAdvisor],
["SvgoAdvisorBlack", LazySvgoAdvisorBlack],
["SvgoAdvisorGray", LazySvgoAdvisorGray],
["SvgoAdvisorText", LazySvgoAdvisorText],
["SvgoArrowDown", LazySvgoArrowDown],
["SvgoArrowDownFilled", LazySvgoArrowDownFilled],
["SvgoArrowLeft", LazySvgoArrowLeft],
["SvgoArrowRight", LazySvgoArrowRight],
["SvgoArrowUp", LazySvgoArrowUp],
["SvgoArrowUpFilled", LazySvgoArrowUpFilled],
["SvgoBook", LazySvgoBook],
["SvgoCamera", LazySvgoCamera],
["SvgoChange", LazySvgoChange],
["SvgoCheck", LazySvgoCheck],
["SvgoClearCircle", LazySvgoClearCircle],
["SvgoClearWithBackground", LazySvgoClearWithBackground],
["SvgoClickHere", LazySvgoClickHere],
["SvgoClose", LazySvgoClose],
["SvgoCloseWithBackground", LazySvgoCloseWithBackground],
["SvgoCollapse", LazySvgoCollapse],
["SvgoCompanies", LazySvgoCompanies],
["SvgoCross", LazySvgoCross],
["SvgoDashboard", LazySvgoDashboard],
["SvgoDashboardOutline", LazySvgoDashboardOutline],
["SvgoDownload", LazySvgoDownload],
["SvgoEdit", LazySvgoEdit],
["SvgoEllipse", LazySvgoEllipse],
["SvgoEmail", LazySvgoEmail],
["SvgoError", LazySvgoError],
["SvgoExpand", LazySvgoExpand],
["SvgoGlobalWorld", LazySvgoGlobalWorld],
["SvgoGridView", LazySvgoGridView],
["SvgoHamburger", LazySvgoHamburger],
["SvgoInfo", LazySvgoInfo],
["SvgoListView", LazySvgoListView],
["SvgoLocation", LazySvgoLocation],
["SvgoLock", LazySvgoLock],
["SvgoMitigateCheck", LazySvgoMitigateCheck],
["SvgoMoreFilled", LazySvgoMoreFilled],
["SvgoMyProfile", LazySvgoMyProfile],
["SvgoPdf", LazySvgoPdf],
["SvgoPhone", LazySvgoPhone],
["SvgoPlus", LazySvgoPlus],
["SvgoProjects", LazySvgoProjects],
["SvgoProjectsAddNew", LazySvgoProjectsAddNew],
["SvgoProtectasTextLogo", LazySvgoProtectasTextLogo],
["SvgoProtectasTextLogoBlack", LazySvgoProtectasTextLogoBlack],
["SvgoRiskAssessment", LazySvgoRiskAssessment],
["SvgoSearch", LazySvgoSearch],
["SvgoSecuritasLogo", LazySvgoSecuritasLogo],
["SvgoSecuritasTextLogo", LazySvgoSecuritasTextLogo],
["SvgoSecuritasTextLogoBlack", LazySvgoSecuritasTextLogoBlack],
["SvgoShare", LazySvgoShare],
["SvgoStatusCompleted", LazySvgoStatusCompleted],
["SvgoStatusDraft", LazySvgoStatusDraft],
["SvgoStatusInProgress", LazySvgoStatusInProgress],
["SvgoSuccess", LazySvgoSuccess],
["SvgoThreeDots", LazySvgoThreeDots],
["SvgoTooltipArrowUp", LazySvgoTooltipArrowUp],
["SvgoTrash", LazySvgoTrash],
["SvgoUpload", LazySvgoUpload],
["SvgoUsers", LazySvgoUsers],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
