
// @ts-nocheck
import locale__working_dir_locales_en_0_json from "../locales/en-0.json";
import locale__working_dir_locales_sr_json from "../locales/sr.json";
import locale__working_dir_locales_bs_json from "../locales/bs.json";
import locale__working_dir_locales_hr_json from "../locales/hr.json";
import locale__working_dir_locales_cs_json from "../locales/cs.json";
import locale__working_dir_locales_da_json from "../locales/da.json";
import locale__working_dir_locales_nl_json from "../locales/nl.json";
import locale__working_dir_locales_en_au_json from "../locales/en-au.json";
import locale__working_dir_locales_en_gb_json from "../locales/en-gb.json";
import locale__working_dir_locales_fi_json from "../locales/fi.json";
import locale__working_dir_locales_nl_be_json from "../locales/nl-be.json";
import locale__working_dir_locales_fr_json from "../locales/fr.json";
import locale__working_dir_locales_fr_be_json from "../locales/fr-be.json";
import locale__working_dir_locales_fr_ch_json from "../locales/fr-ch.json";
import locale__working_dir_locales_de_at_json from "../locales/de-at.json";
import locale__working_dir_locales_de_ch_json from "../locales/de-ch.json";
import locale__working_dir_locales_hu_json from "../locales/hu.json";
import locale__working_dir_locales_it_ch_json from "../locales/it-ch.json";
import locale__working_dir_locales_no_json from "../locales/no.json";
import locale__working_dir_locales_pl_json from "../locales/pl.json";
import locale__working_dir_locales_pt_json from "../locales/pt.json";
import locale__working_dir_locales_ro_json from "../locales/ro.json";
import locale__working_dir_locales_sk_json from "../locales/sk.json";
import locale__working_dir_locales_sv_json from "../locales/sv.json";
import locale__working_dir_locales_de_de_json from "../locales/de-de.json";
import locale__working_dir_locales_tr_json from "../locales/tr.json";
import locale__working_dir_locales_es_json from "../locales/es.json";


export const localeCodes =  [
  "en",
  "sr",
  "bs",
  "hr",
  "cs",
  "da",
  "nl",
  "en-au",
  "en-gb",
  "fi",
  "nl-be",
  "fr",
  "fr-be",
  "fr-ch",
  "de-at",
  "de-ch",
  "hu",
  "it-ch",
  "no",
  "pl",
  "pt",
  "ro",
  "sk",
  "sv",
  "de",
  "tr",
  "es"
]

export const localeLoaders = {
  "en": [{ key: "../locales/en-0.json", load: () => Promise.resolve(locale__working_dir_locales_en_0_json), cache: true }],
  "sr": [{ key: "../locales/sr.json", load: () => Promise.resolve(locale__working_dir_locales_sr_json), cache: true }],
  "bs": [{ key: "../locales/bs.json", load: () => Promise.resolve(locale__working_dir_locales_bs_json), cache: true }],
  "hr": [{ key: "../locales/hr.json", load: () => Promise.resolve(locale__working_dir_locales_hr_json), cache: true }],
  "cs": [{ key: "../locales/cs.json", load: () => Promise.resolve(locale__working_dir_locales_cs_json), cache: true }],
  "da": [{ key: "../locales/da.json", load: () => Promise.resolve(locale__working_dir_locales_da_json), cache: true }],
  "nl": [{ key: "../locales/nl.json", load: () => Promise.resolve(locale__working_dir_locales_nl_json), cache: true }],
  "en-au": [{ key: "../locales/en-au.json", load: () => Promise.resolve(locale__working_dir_locales_en_au_json), cache: true }],
  "en-gb": [{ key: "../locales/en-gb.json", load: () => Promise.resolve(locale__working_dir_locales_en_gb_json), cache: true }],
  "fi": [{ key: "../locales/fi.json", load: () => Promise.resolve(locale__working_dir_locales_fi_json), cache: true }],
  "nl-be": [{ key: "../locales/nl-be.json", load: () => Promise.resolve(locale__working_dir_locales_nl_be_json), cache: true }],
  "fr": [{ key: "../locales/fr.json", load: () => Promise.resolve(locale__working_dir_locales_fr_json), cache: true }],
  "fr-be": [{ key: "../locales/fr-be.json", load: () => Promise.resolve(locale__working_dir_locales_fr_be_json), cache: true }],
  "fr-ch": [{ key: "../locales/fr-ch.json", load: () => Promise.resolve(locale__working_dir_locales_fr_ch_json), cache: true }],
  "de-at": [{ key: "../locales/de-at.json", load: () => Promise.resolve(locale__working_dir_locales_de_at_json), cache: true }],
  "de-ch": [{ key: "../locales/de-ch.json", load: () => Promise.resolve(locale__working_dir_locales_de_ch_json), cache: true }],
  "hu": [{ key: "../locales/hu.json", load: () => Promise.resolve(locale__working_dir_locales_hu_json), cache: true }],
  "it-ch": [{ key: "../locales/it-ch.json", load: () => Promise.resolve(locale__working_dir_locales_it_ch_json), cache: true }],
  "no": [{ key: "../locales/no.json", load: () => Promise.resolve(locale__working_dir_locales_no_json), cache: true }],
  "pl": [{ key: "../locales/pl.json", load: () => Promise.resolve(locale__working_dir_locales_pl_json), cache: true }],
  "pt": [{ key: "../locales/pt.json", load: () => Promise.resolve(locale__working_dir_locales_pt_json), cache: true }],
  "ro": [{ key: "../locales/ro.json", load: () => Promise.resolve(locale__working_dir_locales_ro_json), cache: true }],
  "sk": [{ key: "../locales/sk.json", load: () => Promise.resolve(locale__working_dir_locales_sk_json), cache: true }],
  "sv": [{ key: "../locales/sv.json", load: () => Promise.resolve(locale__working_dir_locales_sv_json), cache: true }],
  "de": [{ key: "../locales/de-de.json", load: () => Promise.resolve(locale__working_dir_locales_de_de_json), cache: true }],
  "tr": [{ key: "../locales/tr.json", load: () => Promise.resolve(locale__working_dir_locales_tr_json), cache: true }],
  "es": [{ key: "../locales/es.json", load: () => Promise.resolve(locale__working_dir_locales_es_json), cache: true }]
}

export const vueI18nConfigs = [
  () => import("../i18n.config.ts?hash=bffaebcb&config=1" /* webpackChunkName: "__i18n_config_ts_bffaebcb" */)
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": "",
    "switchLocalePathLinkSSR": false,
    "autoImportTranslationFunctions": false
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "./i18n.config.ts",
  "locales": [
    {
      "code": "en",
      "iso": "en",
      "name": "English",
      "files": [
        "locales/en-0.json"
      ]
    },
    {
      "code": "sr",
      "iso": "sr-RS",
      "name": "Српски",
      "files": [
        "locales/sr.json"
      ]
    },
    {
      "code": "bs",
      "iso": "bs-BA",
      "name": "Bosanski",
      "files": [
        "locales/bs.json"
      ]
    },
    {
      "code": "hr",
      "iso": "hr-HR",
      "name": "Hrvatski",
      "files": [
        "locales/hr.json"
      ]
    },
    {
      "code": "cs",
      "iso": "cs-CZ",
      "name": "Čeština",
      "files": [
        "locales/cs.json"
      ]
    },
    {
      "code": "da",
      "iso": "da-DK",
      "name": "Dansk",
      "files": [
        "locales/da.json"
      ]
    },
    {
      "code": "nl",
      "iso": "nl-NL",
      "name": "Nederlands",
      "files": [
        "locales/nl.json"
      ]
    },
    {
      "code": "en-au",
      "iso": "en-AU",
      "name": "English (Australia)",
      "files": [
        "locales/en-au.json"
      ]
    },
    {
      "code": "en-gb",
      "iso": "en-GB",
      "name": "English (UK)",
      "files": [
        "locales/en-gb.json"
      ]
    },
    {
      "code": "fi",
      "iso": "fi-FI",
      "name": "Suomi",
      "files": [
        "locales/fi.json"
      ]
    },
    {
      "code": "nl-be",
      "iso": "nl-BE",
      "name": "Vlaams",
      "files": [
        "locales/nl-be.json"
      ]
    },
    {
      "code": "fr",
      "iso": "fr-FR",
      "name": "Français",
      "files": [
        "locales/fr.json"
      ]
    },
    {
      "code": "fr-be",
      "iso": "fr-BE",
      "name": "Français (Belgique)",
      "files": [
        "locales/fr-be.json"
      ]
    },
    {
      "code": "fr-ch",
      "iso": "fr-CH",
      "name": "Français (Suisse)",
      "files": [
        "locales/fr-ch.json"
      ]
    },
    {
      "code": "de-at",
      "iso": "de-AT",
      "name": "Deutsch (Österreich)",
      "files": [
        "locales/de-at.json"
      ]
    },
    {
      "code": "de-ch",
      "iso": "de-CH",
      "name": "Deutsch (Schweiz)",
      "files": [
        "locales/de-ch.json"
      ]
    },
    {
      "code": "hu",
      "iso": "hu-HU",
      "name": "Magyar",
      "files": [
        "locales/hu.json"
      ]
    },
    {
      "code": "it-ch",
      "iso": "it-CH",
      "name": "Italiano (Svizzera)",
      "files": [
        "locales/it-ch.json"
      ]
    },
    {
      "code": "no",
      "iso": "no-NO",
      "name": "Norsk",
      "files": [
        "locales/no.json"
      ]
    },
    {
      "code": "pl",
      "iso": "pl-PL",
      "name": "Polski",
      "files": [
        "locales/pl.json"
      ]
    },
    {
      "code": "pt",
      "iso": "pt-PT",
      "name": "Português",
      "files": [
        "locales/pt.json"
      ]
    },
    {
      "code": "ro",
      "iso": "ro-RO",
      "name": "Română",
      "files": [
        "locales/ro.json"
      ]
    },
    {
      "code": "sk",
      "iso": "sk-SK",
      "name": "Slovenčina",
      "files": [
        "locales/sk.json"
      ]
    },
    {
      "code": "sv",
      "iso": "sv-SE",
      "name": "Svenska",
      "files": [
        "locales/sv.json"
      ]
    },
    {
      "code": "de",
      "iso": "de-DE",
      "name": "Deutsch (Deutschland)",
      "files": [
        "locales/de-de.json"
      ]
    },
    {
      "code": "tr",
      "iso": "tr-TR",
      "name": "Türkçe",
      "files": [
        "locales/tr.json"
      ]
    },
    {
      "code": "es",
      "iso": "es-ES",
      "name": "Español (España)",
      "files": [
        "locales/es.json"
      ]
    }
  ],
  "defaultLocale": "en",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "no_prefix",
  "lazy": false,
  "langDir": "locales/",
  "detectBrowserLanguage": false,
  "differentDomains": false,
  "baseUrl": "",
  "dynamicRouteParams": false,
  "customRoutes": "page",
  "pages": {},
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "code": "en",
    "iso": "en",
    "name": "English",
    "files": [
      {
        "path": "locales/en-0.json"
      }
    ]
  },
  {
    "code": "sr",
    "iso": "sr-RS",
    "name": "Српски",
    "files": [
      {
        "path": "locales/sr.json"
      }
    ]
  },
  {
    "code": "bs",
    "iso": "bs-BA",
    "name": "Bosanski",
    "files": [
      {
        "path": "locales/bs.json"
      }
    ]
  },
  {
    "code": "hr",
    "iso": "hr-HR",
    "name": "Hrvatski",
    "files": [
      {
        "path": "locales/hr.json"
      }
    ]
  },
  {
    "code": "cs",
    "iso": "cs-CZ",
    "name": "Čeština",
    "files": [
      {
        "path": "locales/cs.json"
      }
    ]
  },
  {
    "code": "da",
    "iso": "da-DK",
    "name": "Dansk",
    "files": [
      {
        "path": "locales/da.json"
      }
    ]
  },
  {
    "code": "nl",
    "iso": "nl-NL",
    "name": "Nederlands",
    "files": [
      {
        "path": "locales/nl.json"
      }
    ]
  },
  {
    "code": "en-au",
    "iso": "en-AU",
    "name": "English (Australia)",
    "files": [
      {
        "path": "locales/en-au.json"
      }
    ]
  },
  {
    "code": "en-gb",
    "iso": "en-GB",
    "name": "English (UK)",
    "files": [
      {
        "path": "locales/en-gb.json"
      }
    ]
  },
  {
    "code": "fi",
    "iso": "fi-FI",
    "name": "Suomi",
    "files": [
      {
        "path": "locales/fi.json"
      }
    ]
  },
  {
    "code": "nl-be",
    "iso": "nl-BE",
    "name": "Vlaams",
    "files": [
      {
        "path": "locales/nl-be.json"
      }
    ]
  },
  {
    "code": "fr",
    "iso": "fr-FR",
    "name": "Français",
    "files": [
      {
        "path": "locales/fr.json"
      }
    ]
  },
  {
    "code": "fr-be",
    "iso": "fr-BE",
    "name": "Français (Belgique)",
    "files": [
      {
        "path": "locales/fr-be.json"
      }
    ]
  },
  {
    "code": "fr-ch",
    "iso": "fr-CH",
    "name": "Français (Suisse)",
    "files": [
      {
        "path": "locales/fr-ch.json"
      }
    ]
  },
  {
    "code": "de-at",
    "iso": "de-AT",
    "name": "Deutsch (Österreich)",
    "files": [
      {
        "path": "locales/de-at.json"
      }
    ]
  },
  {
    "code": "de-ch",
    "iso": "de-CH",
    "name": "Deutsch (Schweiz)",
    "files": [
      {
        "path": "locales/de-ch.json"
      }
    ]
  },
  {
    "code": "hu",
    "iso": "hu-HU",
    "name": "Magyar",
    "files": [
      {
        "path": "locales/hu.json"
      }
    ]
  },
  {
    "code": "it-ch",
    "iso": "it-CH",
    "name": "Italiano (Svizzera)",
    "files": [
      {
        "path": "locales/it-ch.json"
      }
    ]
  },
  {
    "code": "no",
    "iso": "no-NO",
    "name": "Norsk",
    "files": [
      {
        "path": "locales/no.json"
      }
    ]
  },
  {
    "code": "pl",
    "iso": "pl-PL",
    "name": "Polski",
    "files": [
      {
        "path": "locales/pl.json"
      }
    ]
  },
  {
    "code": "pt",
    "iso": "pt-PT",
    "name": "Português",
    "files": [
      {
        "path": "locales/pt.json"
      }
    ]
  },
  {
    "code": "ro",
    "iso": "ro-RO",
    "name": "Română",
    "files": [
      {
        "path": "locales/ro.json"
      }
    ]
  },
  {
    "code": "sk",
    "iso": "sk-SK",
    "name": "Slovenčina",
    "files": [
      {
        "path": "locales/sk.json"
      }
    ]
  },
  {
    "code": "sv",
    "iso": "sv-SE",
    "name": "Svenska",
    "files": [
      {
        "path": "locales/sv.json"
      }
    ]
  },
  {
    "code": "de",
    "iso": "de-DE",
    "name": "Deutsch (Deutschland)",
    "files": [
      {
        "path": "locales/de-de.json"
      }
    ]
  },
  {
    "code": "tr",
    "iso": "tr-TR",
    "name": "Türkçe",
    "files": [
      {
        "path": "locales/tr.json"
      }
    ]
  },
  {
    "code": "es",
    "iso": "es-ES",
    "name": "Español (España)",
    "files": [
      {
        "path": "locales/es.json"
      }
    ]
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18n"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
