import revive_payload_client_4sVQNw7RlN from "/working_dir/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/working_dir/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/working_dir/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_jtc2qNDx4l from "/working_dir/node_modules/nuxt-site-config/dist/runtime/app/plugins/0.siteConfig.js";
import payload_client_yVLowv6hDl from "/working_dir/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/working_dir/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/working_dir/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/working_dir/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/working_dir/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/working_dir/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/working_dir/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_UYiXMU8ZyN from "/working_dir/node_modules/nuxt-gtag/dist/runtime/plugin.client.js";
import switch_locale_path_ssr_8BKffvaEi5 from "/working_dir/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.mjs";
import i18n_yfWm7jX06p from "/working_dir/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import siteConfig_JRId4KOeUL from "/working_dir/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/siteConfig.js";
import inferSeoMetaPlugin_JSh5nGhzCz from "/working_dir/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/inferSeoMetaPlugin.js";
import titles_eoILE7jqvj from "/working_dir/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/titles.js";
import defaultsWaitI18n_lla9AZgoHk from "/working_dir/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/defaultsWaitI18n.js";
import defaults_HbhxXswM5t from "/working_dir/node_modules/nuxt-schema-org/dist/runtime/app/plugins/i18n/defaults.js";
import pwa_icons_plugin_LnrhIJeMG7 from "/working_dir/.nuxt/pwa-icons-plugin.ts";
import pwa_client_Eorgdr12jA from "/working_dir/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.mjs";
import plugin_AUP22rrBAc from "/working_dir/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import _01_msal_bvpe6Ogpdb from "/working_dir/plugins/01.msal.ts";
import _02_translation_VO5CqBmKNZ from "/working_dir/plugins/02.translation.ts";
import _03_sentry_client_RmhACl5F9Y from "/working_dir/plugins/03.sentry.client.ts";
import init_678KNwVQOH from "/working_dir/node_modules/nuxt-schema-org/dist/runtime/app/plugins/i18n/init.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_jtc2qNDx4l,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_client_UYiXMU8ZyN,
  switch_locale_path_ssr_8BKffvaEi5,
  i18n_yfWm7jX06p,
  siteConfig_JRId4KOeUL,
  inferSeoMetaPlugin_JSh5nGhzCz,
  titles_eoILE7jqvj,
  defaultsWaitI18n_lla9AZgoHk,
  defaults_HbhxXswM5t,
  pwa_icons_plugin_LnrhIJeMG7,
  pwa_client_Eorgdr12jA,
  plugin_AUP22rrBAc,
  _01_msal_bvpe6Ogpdb,
  _02_translation_VO5CqBmKNZ,
  _03_sentry_client_RmhACl5F9Y,
  init_678KNwVQOH
]