export default defineNuxtPlugin(async (nuxtApp) => {
  const { setupUserLanguage } = useLanguageSetup()
  const { translations } = storeToRefs(useLanguageStore())
  const { isProtectasUser } = storeToRefs(useAuthStore())

  await setupUserLanguage()
  // watch for userContext to be available or changed and setup user language
  nuxtApp.hooks.hook('app:mounted', async () => {
    const authStore = useAuthStore()
    const { userContext } = storeToRefs(authStore)
    watch(
      () => userContext.value?.language,
      async () => {
        await setupUserLanguage()
      },
    )
  })

  // provide translation function globally
  return {
    provide: {
      tx: (msg?: string) => {
        if (!msg)
          return msg
        return translations.value instanceof Map
          ? (translations.value.get(msg) ?? msg)
          : msg
      },
      isProtectasUser,
      trp: (originalText?: string) => {
        if (!originalText)
          return originalText
        return isProtectasUser.value
          ? originalText.replace(/Securitas/g, 'Protectas')
          : originalText
      },
    },
  }
})
