// setup interface language and fetch translations
export function useLanguageSetup() {
  const i18n = useNuxtApp().$i18n
  const locales = i18n.locales?.value
  const setLocale = i18n.setLocale

  const authStore = useAuthStore()
  const { userContext } = storeToRefs(authStore)
  const { fetchTranslations } = useLanguageStore()
  const { interfaceLanguage, translations } = storeToRefs(useLanguageStore())

  const setupUserLanguage = async () => {
    const userContextLanguage = userContext.value?.language
    // English is set when userContext is not available
    setLocale(userContextLanguage?.tag ?? locales[0].iso ?? 'en')
    if (userContextLanguage) {
      interfaceLanguage.value = locales.find(
        lang =>
          lang.iso?.toLowerCase() === userContextLanguage.tag?.toLowerCase(),
      ) as Language
      await fetchTranslations(ref(false), userContextLanguage.id)
    }
    else {
      translations.value = new Map()
      interfaceLanguage.value = locales[0] as Language
    }
  }

  return {
    setupUserLanguage,
  }
}
