export const useLanguageStore = defineStore(
  'language',
  () => {
    const languages = ref<PreferredLanguage[]>([])
    const translations = ref<Map<string, string>>(new Map())
    const interfaceLanguage = ref<Language>()

    const { fetchRequest, patchRequest } = useProjectStore()
    const authStore = useAuthStore()
    const { userContext } = storeToRefs(authStore)

    async function fetchLanguages(fetching: Ref<boolean>) {
      if (languages.value.length > 0) {
        return
      }
      const data = await fetchRequest('languages', fetching)
      if (data) {
        languages.value = data as PreferredLanguage[]
      }
    }

    async function fetchTranslations(
      fetching: Ref<boolean>,
      languageId: string,
    ) {
      if (!languageId) {
        return
      }
      const data = (await fetchRequest(
        `languages/${languageId}/translations`,
        fetching,
      )) as TranslationSource[]
      if (data) {
        translations.value = new Map()
        for (const item of data) {
          if (item.translations && item.translations.length > 0) {
            translations.value.set(
              item.originalText,
              item.translations[0].translatedText,
            )
          }
        }
      }
    }

    async function updateUserLanguage(
      posting: Ref<boolean>,
      languageId?: string,
    ) {
      try {
        const newUserContext = (await patchRequest(
          'users/me/language',
          { languageId },
          posting,
        )) as UserContext
        if (newUserContext) {
          userContext.value = newUserContext
        }
      }
      catch (error) {
        console.error('Failed to update user language:', error)
      }
    }

    return {
      languages,
      translations,
      interfaceLanguage,
      fetchLanguages,
      updateUserLanguage,
      fetchTranslations,
    }
  },
  { persist: true },
)
