import { default as indexJgHzZGZ6MGMeta } from "/working_dir/pages/admin/index.vue?macro=true";
import { default as areasxrUHVDYMo0Meta } from "/working_dir/pages/admin/risk-assessment/areas.vue?macro=true";
import { default as indexoeiSo5wsqLMeta } from "/working_dir/pages/admin/risk-assessment/index.vue?macro=true";
import { default as probability_45impact_45definitionsNzNrYkKS7mMeta } from "/working_dir/pages/admin/risk-assessment/probability-impact-definitions.vue?macro=true";
import { default as indexkTqZoPVNwYMeta } from "/working_dir/pages/admin/risk-assessment/risk-value-chains/[id]/[area]/index.vue?macro=true";
import { default as indexfRKNIjOXcyMeta } from "/working_dir/pages/admin/risk-assessment/risk-value-chains/[id]/index.vue?macro=true";
import { default as indexNJ1Do7GRP8Meta } from "/working_dir/pages/admin/risk-assessment/risk-value-chains/index.vue?macro=true";
import { default as riskstaECWrwS8cMeta } from "/working_dir/pages/admin/risk-assessment/risks.vue?macro=true";
import { default as scenarioseTnHDz9CskMeta } from "/working_dir/pages/admin/risk-assessment/scenarios.vue?macro=true";
import { default as indexgKAm6QJXiWMeta } from "/working_dir/pages/admin/treatment-catalog/index.vue?macro=true";
import { default as newjeEv0zre4RMeta } from "/working_dir/pages/admin/treatment-catalog/new.vue?macro=true";
import { default as usersBTSUOIHJTQMeta } from "/working_dir/pages/admin/users.vue?macro=true";
import { default as indexBEEgJFKpn5Meta } from "/working_dir/pages/index.vue?macro=true";
import { default as profilef5ml0fSwQqMeta } from "/working_dir/pages/profile.vue?macro=true";
import { default as _91area_93Ac5DG8CZWcMeta } from "/working_dir/pages/projects/[id]/[area].vue?macro=true";
import { default as indexuzZZyKUfD1Meta } from "/working_dir/pages/projects/[id]/index.vue?macro=true";
import { default as report8FJeY0ee03Meta } from "/working_dir/pages/projects/[id]/report.vue?macro=true";
import { default as indexpGcZmw5uqbMeta } from "/working_dir/pages/projects/index.vue?macro=true";
import { default as newqkzsKep2bmMeta } from "/working_dir/pages/projects/new.vue?macro=true";
import { default as indexNYbVLDYp35Meta } from "/working_dir/pages/treatment-catalog/index.vue?macro=true";
export default [
  {
    name: "admin",
    path: "/admin",
    meta: indexJgHzZGZ6MGMeta || {},
    component: () => import("/working_dir/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-risk-assessment-areas",
    path: "/admin/risk-assessment/areas",
    component: () => import("/working_dir/pages/admin/risk-assessment/areas.vue").then(m => m.default || m)
  },
  {
    name: "admin-risk-assessment",
    path: "/admin/risk-assessment",
    meta: indexoeiSo5wsqLMeta || {},
    component: () => import("/working_dir/pages/admin/risk-assessment/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-risk-assessment-probability-impact-definitions",
    path: "/admin/risk-assessment/probability-impact-definitions",
    component: () => import("/working_dir/pages/admin/risk-assessment/probability-impact-definitions.vue").then(m => m.default || m)
  },
  {
    name: "admin-risk-assessment-risk-value-chains-id-area",
    path: "/admin/risk-assessment/risk-value-chains/:id()/:area()",
    component: () => import("/working_dir/pages/admin/risk-assessment/risk-value-chains/[id]/[area]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-risk-assessment-risk-value-chains-id",
    path: "/admin/risk-assessment/risk-value-chains/:id()",
    component: () => import("/working_dir/pages/admin/risk-assessment/risk-value-chains/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-risk-assessment-risk-value-chains",
    path: "/admin/risk-assessment/risk-value-chains",
    meta: indexNJ1Do7GRP8Meta || {},
    component: () => import("/working_dir/pages/admin/risk-assessment/risk-value-chains/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-risk-assessment-risks",
    path: "/admin/risk-assessment/risks",
    component: () => import("/working_dir/pages/admin/risk-assessment/risks.vue").then(m => m.default || m)
  },
  {
    name: "admin-risk-assessment-scenarios",
    path: "/admin/risk-assessment/scenarios",
    component: () => import("/working_dir/pages/admin/risk-assessment/scenarios.vue").then(m => m.default || m)
  },
  {
    name: "admin-treatment-catalog",
    path: "/admin/treatment-catalog",
    meta: indexgKAm6QJXiWMeta || {},
    component: () => import("/working_dir/pages/admin/treatment-catalog/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-treatment-catalog-new",
    path: "/admin/treatment-catalog/new",
    component: () => import("/working_dir/pages/admin/treatment-catalog/new.vue").then(m => m.default || m)
  },
  {
    name: "admin-users",
    path: "/admin/users",
    component: () => import("/working_dir/pages/admin/users.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: indexBEEgJFKpn5Meta || {},
    component: () => import("/working_dir/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "profile",
    path: "/profile",
    component: () => import("/working_dir/pages/profile.vue").then(m => m.default || m)
  },
  {
    name: "projects-id-area",
    path: "/projects/:id()/:area()",
    meta: _91area_93Ac5DG8CZWcMeta || {},
    component: () => import("/working_dir/pages/projects/[id]/[area].vue").then(m => m.default || m)
  },
  {
    name: "projects-id",
    path: "/projects/:id()",
    meta: indexuzZZyKUfD1Meta || {},
    component: () => import("/working_dir/pages/projects/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "projects-id-report",
    path: "/projects/:id()/report",
    component: () => import("/working_dir/pages/projects/[id]/report.vue").then(m => m.default || m)
  },
  {
    name: "projects",
    path: "/projects",
    meta: indexpGcZmw5uqbMeta || {},
    component: () => import("/working_dir/pages/projects/index.vue").then(m => m.default || m)
  },
  {
    name: "projects-new",
    path: "/projects/new",
    meta: newqkzsKep2bmMeta || {},
    component: () => import("/working_dir/pages/projects/new.vue").then(m => m.default || m)
  },
  {
    name: "treatment-catalog",
    path: "/treatment-catalog",
    component: () => import("/working_dir/pages/treatment-catalog/index.vue").then(m => m.default || m)
  }
]